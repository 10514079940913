import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import {
  Link,
  Trans,
  useTranslation,
  useI18next,
} from "gatsby-plugin-react-i18next";
// import { gsap } from "gsap"
// import { ScrollToPlugin } from "gsap/ScrollToPlugin"
// import { ScrollTrigger } from "gsap/ScrollTrigger"
// import navIcon1 from "../images/nav-icon1.svg"
// import navIcon2 from "../images/nav-icon2.svg"
// import navIcon3 from "../images/nav-icon3.svg"

// import Sidebar from "./Sidebar"

// import { Link } from "gatsby"

// import {
//   BrowserView,
//   MobileView,
//   isBrowser,
//   isMobile,
// } from "react-device-detect"

import "../css/header.scss";
import "../css/sidebar.scss";

import navIcon from "../images/nav-icon.svg";
import navDot from "../images/nav-dot.svg";
import navIconOpen from "../images/nav-icon-open.svg";
// import menuVideo from "../video/menu-video-big.mp4"
import button from "../images/button.svg";

// class Header extends Component {
//   constructor(props, context) {
//     super(props, context)

//     this.state = {
//       visible: false,
//     }

//     this.handleMouseDown = this.handleMouseDown.bind(this)
//     this.toggleMenu = this.toggleMenu.bind(this)
//   }

//   handleMouseDown(e) {
//     this.toggleMenu()

//     console.log("clicked")
//     e.stopPropagation()
//   }

//   toggleMenu() {
//     this.setState({
//       visible: !this.state.visible,
//     })
//   }

const Header = (props) => {
  const isMedia768 = useMediaQuery({
    query: "(min-device-width: 768px)",
  });
  const { t } = useTranslation();
  const { language, languages, originalPath } = useI18next();

  var lngTo = "ru";
  {
    language == "ru" ? (lngTo = "en") : (lngTo = "ru");
  }

  // const bgGradient = props.bgGradient
  // const fullWidth = props.fullWidth
  // const openMenu = () => {
  //   var el = document.getElementById("menuSide")
  //   var elOverlay = document.getElementById("overlay")
  //   var elBody = document.body
  //   // elBody.classList.add("no-scroll")
  //   elOverlay.classList.add("overlay-on")
  //   el.classList.add("menu-wrapper_open")
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  function toggleMenu(event) {
    // console.log("---header before isSidebarOpen", isSidebarOpen)
    setIsSidebarOpen(!isSidebarOpen);
  }

  useEffect(() => {
    // console.log("---header after isSidebarOpen", isSidebarOpen)

    const box = document.querySelector("h1");
    // debugger
    // gsap.set("h1", { height: 500 })

    // ScrollTrigger.refresh(true)
    // console.log("---story useeffect")

    props.openMenu(isSidebarOpen);
    // window.scrollTo(0, 10000)
    // window.scrollTo(0, 0)
    // window.scrollTo(0, 10000)
    // window.scrollTo(0, 0)
    // console.log("---scrollto")
  }, [isSidebarOpen]);

  return (
    <>
      {/* <div className="overlay overlay-on"></div> */}
      <div className="flex justify-center">
        <header
          className={`header header-max-width${
            isSidebarOpen ? " header-menu-active" : ""
          }`}
        >
          {/* <div className="menu-side-close" onClick={() => openMenu()}> */}
          <div
            // onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            onClick={toggleMenu}
            className={`menu-dots${isSidebarOpen ? " menu-dots-active" : ""}`}
          >
            {/* <img className="header__icon md:w-13" src={navIcon} alt="menu" /> */}
            <svg
              className="menu-dot menu-dot-top"
              viewBox="0 0 10 10"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1.46377 1.46377C-0.487922 3.41546 -0.487922 6.58454 1.46377 8.53623C3.41546 10.4879 6.58454 10.4879 8.53623 8.53623C10.4879 6.58454 10.4879 3.41546 8.53623 1.46377C6.56522 -0.487922 3.41546 -0.487922 1.46377 1.46377Z" />
            </svg>
            <svg
              className="menu-dot menu-dot-right"
              viewBox="0 0 10 10"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1.46377 1.46377C-0.487922 3.41546 -0.487922 6.58454 1.46377 8.53623C3.41546 10.4879 6.58454 10.4879 8.53623 8.53623C10.4879 6.58454 10.4879 3.41546 8.53623 1.46377C6.56522 -0.487922 3.41546 -0.487922 1.46377 1.46377Z" />
            </svg>
            <svg
              className="menu-dot menu-dot-bottom"
              viewBox="0 0 10 10"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1.46377 1.46377C-0.487922 3.41546 -0.487922 6.58454 1.46377 8.53623C3.41546 10.4879 6.58454 10.4879 8.53623 8.53623C10.4879 6.58454 10.4879 3.41546 8.53623 1.46377C6.56522 -0.487922 3.41546 -0.487922 1.46377 1.46377Z" />
            </svg>
            <svg
              className="menu-dot menu-dot-left"
              viewBox="0 0 10 10"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1.46377 1.46377C-0.487922 3.41546 -0.487922 6.58454 1.46377 8.53623C3.41546 10.4879 6.58454 10.4879 8.53623 8.53623C10.4879 6.58454 10.4879 3.41546 8.53623 1.46377C6.56522 -0.487922 3.41546 -0.487922 1.46377 1.46377Z" />
            </svg>
          </div>

          {isSidebarOpen && !isMedia768 ? (
            <div className="menu-side__block menu-side__block_sm px-2">
              <Link
                className="menu-side__link_sm menu-side__link_inheader link-underline-exp"
                to={originalPath}
                language={lngTo}
              >
                {language == "ru" ? "english" : "по-русски"}
              </Link>
            </div>
          ) : (
            ""
          )}
          {isSidebarOpen && isMedia768 ? (
            <div className="menu-side__block menu-side__block_sm w-1/2 px-2">
              <div>
                <Link
                  className="menu-side__link_sm menu-side__link_inheader link-underline-exp"
                  to={originalPath}
                  language={lngTo}
                >
                  {language == "ru" ? "english" : "по-русски"}
                </Link>
              </div>
              {/* <ul className="languages">
                {languages.map(lng => (
                  <li key={lng}>
                    <Link
                      to={originalPath}
                      language={lng == "ru" ? (lngTo = "en") : (lngTo = "ru")}
                    >
                      {lngTo == "ru" ? <div>english</div> : <div>русский</div>}
                      {lngTo}
                    </Link>
                  </li>
                ))}
              </ul> */}

              {/* <Link
                className="menu-side__link_sm menu-side__link_inheader link-underline"
                to="/"
              >
                english
              </Link> */}
            </div>
          ) : (
            ""
            // <div className="header__text text-2xl1 md:text-5xl text-right leading-6 md:leading-10">
            //   <Link to="/">
            //     Ясная
            //     <br />
            //     поляна
            //   </Link>
            // </div>
          )}

          {!isSidebarOpen && (
            <div className="header__text text-2xl1 md:text-5xl text-right leading-6 md:leading-10">
              <Link to="/">
                <Trans>{t("logoText")}</Trans>
              </Link>
            </div>
          )}
        </header>
      </div>
    </>
  );
};

export default Header;
