import React, { useRef, useState, useEffect } from "react";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";

import { useLocation } from "@reach/router";

import { RemoveScroll } from "react-remove-scroll";
// import navIcon1 from "../images/nav-icon1.svg"
// import navIcon2 from "../images/nav-icon2.svg"
// import navIcon3 from "../images/nav-icon3.svg"

// import Sidebar from "./Sidebar"

// import { Link } from "gatsby";

import { useMediaQuery } from "react-responsive";
// import MediaQuery from "react-responsive"
import { useBreakpoint } from "gatsby-plugin-breakpoints";

import "../css/header.scss";
import "../css/sidebar.scss";

import navIcon from "../images/nav-icon.svg";
import navDot from "../images/nav-dot.svg";
import navIconOpen from "../images/nav-icon-open.svg";
// import menuVideoBig from "../video/yasnaya-polyana.mp4"
import menuVideoBig from "../video/menu-video-big.mp4";
import menuVideoSm from "../video/menu-video-sm.mp4";
import button from "../images/button.svg";

// class Header extends Component {
//   constructor(props, context) {
//     super(props, context)

//     this.state = {
//       visible: false,
//     }

//     this.handleMouseDown = this.handleMouseDown.bind(this)
//     this.toggleMenu = this.toggleMenu.bind(this)
//   }

//   handleMouseDown(e) {
//     this.toggleMenu()

//     console.log("clicked")
//     e.stopPropagation()
//   }

//   toggleMenu() {
//     this.setState({
//       visible: !this.state.visible,
//     })
//   }

const Sidebar = (props) => {
  const location = useLocation();
  const locationWithSlash = location.pathname;
  const locationWOSlash = locationWithSlash.endsWith("/")
    ? locationWithSlash.slice(0, -1)
    : locationWithSlash;
  const locationName = locationWOSlash.startsWith("/en")
    ? locationWOSlash.slice(3)
    : locationWOSlash;

  const videoSideRef = useRef(null);

  const { t } = useTranslation();

  const breakpoints = useBreakpoint();
  // debugger;
  // const bgGradient = props.bgGradient
  // const fullWidth = props.fullWidth
  // const openMenu = () => {
  //   var el = document.getElementById("menuSide")
  //   var elOverlay = document.getElementById("overlay")
  //   var elBody = document.body
  //   // elBody.classList.add("no-scroll")
  //   elOverlay.classList.add("overlay-on")
  //   el.classList.add("menu-wrapper_open")
  // const [isSidebarOpen, setIsSidebarOpen] = useState(false)

  // function toggleMenu(event) {
  //   setIsSidebarOpen(!isSidebarOpen)
  // }

  // console.log("--- sidebar actieMenu", props.activeMenu)

  // useEffect(() => {
  //   props.openMenu(isSidebarOpen)
  // }, [isSidebarOpen])

  return (
    <>
      {/* <Sidebar /> */}

      {/* <RemoveScroll enabled={props.activeMenu}> */}
      <div
        className={`menu-side-wrapper${
          props.activeMenu ? " menu-side-wrapper_open" : ""
        }`}
      >
        <div className="menu-side-outer">
          {/* <div className="menu-side-video">
              <video
                className="menu-side-video1"
                autoPlay
                playsInline
                muted
                loop
                // preload
              >
                <source src={menuVideo} />
              </video>
            </div> */}
          <div
            className={`menu-side-inner px-4 md:px-16${
              props.activeMenu ? "" : ""
            }`}
            id="menuSide"
          >
            {breakpoints.sm ? (
              <video
                ref={videoSideRef}
                key="videoSm"
                className="menu-side-video"
                autoPlay
                playsInline
                muted
                loop
              >
                <source src={menuVideoSm} />
              </video>
            ) : (
              <video
                ref={videoSideRef}
                key="videoBig"
                className="menu-side-video"
                autoPlay
                playsInline
                muted
                loop
              >
                <source src={menuVideoBig} />
              </video>
            )}
            <div className="menu-side md:-mx-2">
              <div className="menu-side_big w-full md:w-1/2 md:px-2">
                <div className="menu-side__block">
                  {locationName == "/products" ? (
                    <div className="menu-side__link link-underline menu-current">
                      {t("products")}
                    </div>
                  ) : (
                    <Link
                      className="menu-side__link link-underline"
                      to="/products"
                    >
                      {t("products")}
                    </Link>
                  )}
                </div>
                <div className="menu-side__block">
                  {locationName == "/idea" ? (
                    <div className="menu-side__link link-underline menu-current">
                      {t("idea")}
                    </div>
                  ) : (
                    <Link className="menu-side__link link-underline" to="/idea">
                      {t("idea")}
                    </Link>
                  )}
                </div>
                <div className="menu-side__block">
                  {locationName == "/story" ? (
                    <div className="menu-side__link link-underline menu-current">
                      {t("story")}
                    </div>
                  ) : (
                    <Link
                      className="menu-side__link link-underline"
                      to="/story"
                    >
                      {t("story")}
                    </Link>
                  )}
                </div>

                <div className="menu-side__block">
                  {locationName == "/orchards" ? (
                    <div className="menu-side__link link-underline menu-current">
                      {t("orchards")}
                    </div>
                  ) : (
                    <Link
                      className="menu-side__link link-underline"
                      to="/orchards"
                    >
                      {t("orchards")}
                    </Link>
                  )}
                </div>
              </div>
              <div className="menu-side_sm w-full flex flex-wrap md:w-1/2 -mx-2 md:px-2">
                <div className="menu-side__block menu-side__block_sm w-1/2 md:w-full md:order-1 px-2">
                  {locationName == "/markets" ? (
                    <div className="menu-side__link_sm link-underline menu-current">
                      {t("whereToBuy")}
                    </div>
                  ) : (
                    <Link
                      className="menu-side__link_sm link-underline"
                      to="/markets"
                    >
                      {t("whereToBuy")}
                    </Link>
                  )}
                </div>
                <div className="menu-side__block menu-side__block_sm w-1/2 md:w-full md:order-2 px-2">
                  {locationName == "/contact" ? (
                    <div className="menu-side__link_sm link-underline menu-current">
                      {t("contacts")}
                    </div>
                  ) : (
                    <Link
                      className="menu-side__link_sm link-underline"
                      to="/contact"
                    >
                      {t("contacts")}
                    </Link>
                  )}
                </div>
                <div className="menu-side__block menu-side__block_sm menu-side__block_disable md:w-full md:order-3  w-1/2 px-2">
                  {/* <Link className="menu-side__link_sm" to="/"> */}
                  <div className="menu-side__link_sm link-underline">
                    {t("delivery")}
                  </div>
                  {/* </Link> */}
                </div>
                <div className="menu-side__block menu-side__block_sm menu-side__block_disable w-1/2 md:w-full md:order-4 px-2">
                  {/* <Link className="menu-side__link_sm" to="/"> */}
                  <div className="menu-side__link_sm link-underline">
                    {t("blog")}
                  </div>
                  {/* </Link> */}
                </div>
              </div>
            </div>

            <div className="menu-bottom-wrapper">
              <div className="menu-side__subscription">
                <div className="w-full">
                  <input
                    className="menu-side__input menu-side__input_top appearance-none bg-transparent border-none w-full mr-3 py-1 leading-tight focus:outline-none"
                    id="subscriptionEmail"
                    type="email"
                    placeholder={t("yourEmail")}
                  />
                </div>

                <div className="subscription-menu_link">
                  <Link to="/" className="">
                    <svg
                      className="subscription-button"
                      width={22}
                      height={22}
                      viewBox="0 0 22 22"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18.3554 3.64455C22.4149 7.70396 22.4149 14.296 18.3554 18.3554C14.2976 22.4133 7.74725 22.4167 3.64362 18.3545C-0.414851 14.295 -0.41454 7.70364 3.64455 3.64455C7.70395 -0.414853 14.296 -0.414853 18.3554 3.64455Z"
                        strokeWidth={1.2}
                      />
                    </svg>
                    {/* <img
                        className="subscription-button"
                        src={button}
                        alt=""
                      /> */}
                  </Link>
                </div>
              </div>

              <div className="menu-sidebar-footer flex flex-wrap -mx-2">
                <div className="w-1/2 px-2">
                  {t("tm")}
                  <br className="md:hidden" />
                  {t("yasnGardens")}
                </div>
                <div className="w-1/2 px-2 md:text-right">
                  <span className="hidden md:inline">{t("madeBy")}</span>
                  <a
                    href="https://studiotransformer.ru/"
                    className="link-underline"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="md:hidden">
                      {t("madeByStudio")}
                      <br />
                      {t("transformer")}
                    </span>
                    <span className="hidden md:inline">
                      {t("inTransformerStudio")}
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
      {/* </div> */}
      {/* </div> */}
      {/* </RemoveScroll> */}
    </>
  );
};

export default Sidebar;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["sidebar"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
