import React, { useState, useEffect } from "react";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
// import { gsap } from "gsap"
// import { ScrollTrigger } from "gsap/ScrollTrigger"

import Header from "./Header";
import Sidebar from "./Sidebar";

import "../css/tailwind.css";
import "../css/main.scss";

//---dev---
// import "./Dev.js"

if (typeof window !== `undefined`) {
  // gsap.registerPlugin(ScrollTrigger)
  // gsap.core.globals("ScrollTrigger", ScrollTrigger)
}

const Layout = (props) => {
  const [menu, setMenu] = useState(false);
  const { t } = useTranslation();

  function toggleMenu(activeMenu) {
    setMenu(activeMenu);
    // console.log("---layout before menu", menu)
    // debugger
  }

  useEffect(() => {
    // console.log("---header after menu", menu)
    // props.openMenu(isSidebarOpen)
  }, [menu]);

  const children = props.children;
  // const activeMenu = props.activeMenu
  // console.log(activeMenu)
  return (
    <>
      <Helmet
        titleTemplate={`%s - ${t("seoSiteTitle")}`}
        defaultTitle={t("seoSiteTitle")}
      >
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Helmet>

      <Header openMenu={toggleMenu} />

      <div
        id="yasn"
        className={`yasn-base yasn-base_trans${menu ? " yasn-shift" : ""}`}
      >
        <Sidebar activeMenu={menu} />
        {children}
      </div>
      <div className="dev---overlay hidden"></div>
      <div id="overlay" className="overlay"></div>
    </>
  );
};

export default Layout;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

//  <header ref={headerRef} className="container">
//       <img src={figures} ref={imgRef} className="logo" alt="logo" />
//     </header>

// <main className="App-main">
//       {sections.map(({ title, subtitle }) => (
//         <div className="App-section" key={title} ref={addToRefs}>
//           <h2>{title}</h2>
//           <p>{subtitle}</p>
//         </div>
//       ))}
//     </main>
